import React, { Component } from 'react';
import { connect } from 'react-redux';
import localeLookup from '../../../config/locale';

import FormModal from '../FormModal';

import InlineFieldEditor from '../../InlineFieldEditor';
import ModalBody from '../ModalBody';
import ModalFooter from '../ModalFooter';
import FormWrapper from '../../formElements/FormWrapper';
import TextField from '../../formElements/TextField';

const mapStateToProps = (state) => ({
  serverErrorMessage: state.userSettings.changePasswordError,
});

class ManageUserProvisioningTokenModal extends Component {
  render() {
    const {
      onClose,
      title,
      confirmButtonText,
      cancelButtonText,
      onCancelClick,
      subtitle,
      setting,
    } = this.props;
    console.log('setting', setting);
    return (
      <FormModal onClose={onClose} title={title} subtitle={subtitle}>
        <ModalBody center>
          <FormWrapper className="create-person-form" onSubmit={this.onSubmit}>
            {/* <TextField
              autoComplete="nope"
              name="currentPassword"
              id="currentPassword"
              required
              label={localeLookup('translations.Current password')}
              type="password"
            /> */}
            <input
              disabled={true}
              onMouseDown={(e) => e.stopPropagation()}
              className={`ui-input inline-field-editor__form-input`}
              type="text"
              value={setting.additionalData.TenantUrl}
              id={'userProvisioningTenantUrl'}
              placeholder={localeLookup('translations.Tenant URL')}
            />
            <input
              disabled={true}
              onMouseDown={(e) => e.stopPropagation()}
              className={`ui-input inline-field-editor__form-input`}
              type="text"
              value={setting.additionalData.TokenId}
              id={'userProvisioningTokenId'}
              placeholder={localeLookup('translations.Tenant Token Identifier')}
            />
          </FormWrapper>
        </ModalBody>
        <ModalFooter
          confirmButtonText={confirmButtonText}
          cancelButtonText={cancelButtonText}
          onCancelClick={onCancelClick}
        />
      </FormModal>
    );
  }
}

export default connect(mapStateToProps)(ManageUserProvisioningTokenModal);

// Components
import { timeZonesNames } from '@vvo/tzdb';
import React, { Component } from 'react';
import localeLookup from '../config/locale';
import { ACCESS_LEVELS, AUTH_ENDPOINT } from '../constants';
import Button from './Button';
import Checkbox from './Checkbox';
import InlineFieldEditor from './InlineFieldEditor';
import InlineSelectEditor from './InlineSelectEditor';
import RadioButton from './RadioButton';
import StatusLabel from './StatusLabel';
import SwitchCheckbox from './SwitchCheckbox';
import AutocompleteSelect from './formElements/AutocompleteSelect';
import Field from './formElements/Field';
import { OrganisationSettingsSection } from './organisation-settings/OrganisationSettingsSection';
import { OrganisationSettingsSectionHeader } from './organisation-settings/OrganisationSettingsSectionHeader';
import WithModals from './HOC/withModals';

export class OrganisationSettings extends Component {
  getGroupName = (id) => {
    const { permissionGroups } = this.props;
    const group = permissionGroups.find((group) => group.id === id);
    if (group) return group.name;
    return '';
  };

  getSupportSetting = () => {
    const { permissions } = this.props;
    const isSupportDisabled = permissions.find(
      (permission) => permission.name === 'DisableIntercomSetting'
    )?.enabled;
    const isSupportLimited = permissions.find(
      (permission) =>
        permission.name === 'AllowSupportChatForSpecificAccessLevels'
    )?.enabled;
    if (isSupportLimited) return 'limited';
    if (isSupportDisabled) return 'none';
    return 'all';
  };

  isSupportAccessLevelEnabled = (accessLevel) => {
    const { permissions } = this.props;
    const allowedAccessLevels = permissions
      .find(
        (permission) =>
          permission.name === 'AllowSupportChatForSpecificAccessLevels'
      )
      ?.additionalData?.AllowedAccessLevels?.split(',');
    return allowedAccessLevels?.includes(accessLevel) || false;
  };

  onChangeSupportAccessLevels = (e) => {
    const { onChangeSupportAccessLevels, permissions } = this.props;
    const id = e.target.id;
    const allowedAccessLevels = permissions
      .find(
        (permission) =>
          permission.name === 'AllowSupportChatForSpecificAccessLevels'
      )
      ?.additionalData?.AllowedAccessLevels?.split(',');

    if (!allowedAccessLevels || allowedAccessLevels === []) {
      onChangeSupportAccessLevels([id]);
    } else if (allowedAccessLevels.includes(id)) {
      onChangeSupportAccessLevels(
        allowedAccessLevels.filter((level) => level !== id)
      );
    } else {
      onChangeSupportAccessLevels([...allowedAccessLevels, id]);
    }
  };

  onChangeSupportSetting = (e) => {
    const { onChangePermission } = this.props;
    const selectedSetting = e.target.value;
    if (selectedSetting === 'all') {
      onChangePermission({ name: 'DisableIntercomSetting', enabled: false });
    } else if (selectedSetting === 'none') {
      onChangePermission({ name: 'DisableIntercomSetting', enabled: true });
    } else if (selectedSetting === 'limited') {
      onChangePermission({
        name: 'AllowSupportChatForSpecificAccessLevels',
        enabled: true,
      });
    }
  };

  renderADSection = () => {
    const { settings, permissions, onChangePermission, user } = this.props;

    return permissions.map((permission, index) => {
      if (permission.name !== 'AzureActiveDirectorySetting') return null;
      const jsonToken = sessionStorage.getItem(
        `oidc.user:${AUTH_ENDPOINT()}:champ`
      );
      const token = JSON.parse(jsonToken)?.access_token;
      let urlWithAccessToken = `/api/systemsettings/azuread/adminconsent?access_token=${token}`;

      if (user?.id !== '') {
        const tenantId = localStorage.getItem(`tenantId_${user.id}`);
        if (tenantId) {
          urlWithAccessToken = `${urlWithAccessToken}&TenantId=${tenantId}`;
        }
      }

      return (
        <OrganisationSettingsSection key={index}>
          <OrganisationSettingsSectionHeader title="Active Directory" />
          {settings.hasExternalUserDirectoryIntegration ? (
            <StatusLabel
              round
              color="green"
              className="organisation-settings__section-status"
            >
              {localeLookup('translations.Connected to Active Directory')}
            </StatusLabel>
          ) : (
            <Button
              className="organisation-settings__section-button"
              href={urlWithAccessToken}
              Element="a"
              kind="darkui"
            >
              {localeLookup('translations.Connect to Active Directory')}
            </Button>
          )}
          <ul className="organisation-settings__section-text-list">
            <li>
              {localeLookup('translations.Automatic sync runs every night')}
            </li>
            <li>
              {localeLookup(
                'translations.Connects Champ and AD users by matching email'
              )}
            </li>
            <li>
              {localeLookup(
                'translations.Updates user info on connected users'
              )}
            </li>
          </ul>

          <ul className="organisation-settings__permissions-list">
            {permissions &&
              permissions.map((permission, index) => {
                if (permission.group !== 'translations.Security') return null;
                return (
                  <li className="organisation-settings__permission" key={index}>
                    <label
                      className="organisation-settings__permission-label"
                      htmlFor={permission.name}
                    >
                      {permission.displayName}
                      {permission.subTitle && (
                        <p className="organisation-settings__permission-subtitle">
                          {permission.subTitle}
                        </p>
                      )}
                    </label>
                    <SwitchCheckbox
                      className="organisation-settings__permission-switch"
                      onChange={() => {
                        onChangePermission({
                          name: permission.name,
                          enabled: !permission.enabled,
                        });
                      }}
                      id={permission.name}
                      isChecked={permission.enabled}
                    />
                  </li>
                );
              })}
          </ul>
        </OrganisationSettingsSection>
      );
    });
  };

  renderApiSection = () => {
    const { permissions, onRotateSecret, permissionGroups } = this.props;
    return permissions.map((permission, index) => {
      if (permission.name !== 'PublicApiSetting') return null;
      return (
        <OrganisationSettingsSection key={index}>
          <OrganisationSettingsSectionHeader title={permission.group} />
          <div className="organisation-settings__section-setting-fields">
            <InlineFieldEditor
              className="organisation-settings__section-field"
              label={localeLookup('translations.Client Id')}
              inputId="apiClientId"
              defaultValue={permission.additionalData.ClientId}
              disabled="true"
            />
          </div>
          <div className="organisation-settings__section-setting-fields">
            <InlineFieldEditor
              className="organisation-settings__section-field"
              label={localeLookup('translations.Client Secret')}
              inputId="apiClientSecret"
              defaultValue={permission.additionalData.ClientSecret}
              disabled="true"
            />
          </div>
          <div className="organisation-settings__section-setting-fields">
            <Button
              type="button"
              onClick={() => {
                onRotateSecret();
              }}
              disabled={false}
              kind="light-grey"
              className="organisation-settings__section-field"
              icon="sync"
            >
              {localeLookup('translations.Rotate secret')}
            </Button>
          </div>
        </OrganisationSettingsSection>
      );
    });
  };

  renderUserProvisioningSection = () => {
    const { permissions, onRotateSecret, permissionGroups, showModal } =
      this.props;
    return permissions.map((permission, index) => {
      if (permission.name !== 'UserProvisioningSetting') return null;
      return (
        <OrganisationSettingsSection key={index}>
          <OrganisationSettingsSectionHeader title={permission.group} />
          <div className="organisation-settings__section-setting-fields">
            <Button
              type="button"
              onClick={() => {
                showModal('manageUserProvisioningToken', {
                  title: localeLookup('translations.Manage provisioning token'),
                  setting: permission,
                });
              }}
              disabled={false}
              kind="light-grey"
              className="organisation-settings__section-field"
            >
              {localeLookup('translations.Manage provisioning token')}
            </Button>
          </div>
        </OrganisationSettingsSection>
      );
    });
  };

  renderMainSettingsSection = () => {
    const {
      timeZone,
      onChangeTimeZone,
      settings,
      onChangeOrganisationName,
      onChangeOrganisationLanguage,
      onChangePermission,
      permissions,
    } = this.props;
    const languageOptions = () => {
      return Object.keys(settings.languages).reduce((acc, key) => {
        acc.push({
          name: settings.languages[key],
          value: key,
        });
        return acc;
      }, []);
    };

    const dateFormatOptions = () => {
      const setting = permissions.find(
        (permission) => permission.name === 'DateFormatSetting'
      );
      const availableFormats = setting?.additionalData?.AvailableFormats;
      if (availableFormats) {
        return Object.keys(availableFormats).map((key) => ({
          name: availableFormats[key],
          value: key,
        }));
      }
      return [];
    };

    const timeFormatOptions = () => {
      const setting = permissions.find(
        (permission) => permission.name === 'TimeFormatSetting'
      );
      const availableFormats = setting?.additionalData?.AvailableFormats;
      if (availableFormats) {
        return Object.keys(availableFormats).map((key) => ({
          name: availableFormats[key],
          value: key,
        }));
      }
      return [];
    };

    const currentDateFormat = () => {
      const setting = permissions.find(
        (permission) => permission.name === 'DateFormatSetting'
      );
      const dateFormat = setting?.additionalData?.DateFormat;
      if (dateFormat) {
        return dateFormat;
      }
      return null;
    };
    const currentTimeFormat = () => {
      const setting = permissions.find(
        (permission) => permission.name === 'TimeFormatSetting'
      );
      const timeFormat = setting?.additionalData?.TimeFormat;
      if (timeFormat) {
        return timeFormat;
      }
      return null;
    };

    return (
      <OrganisationSettingsSection>
        <OrganisationSettingsSectionHeader
          title={localeLookup('translations.Organisation settings')}
        />
        <div className="organisation-settings__section-setting-fields">
          <InlineFieldEditor
            className="organisation-settings__section-field"
            label={localeLookup('translations.Name')}
            inputId="organisationName"
            defaultValue={settings.organisationName}
            onSubmit={(name, resetField) => {
              onChangeOrganisationName(name, resetField);
            }}
          />
          <InlineSelectEditor
            className="organisation-settings__section-field"
            label={localeLookup('translations.language')}
            selectId="language"
            defaultValue={settings.language}
            options={languageOptions()}
            onSubmit={(languageCode, resetSelect) => {
              onChangeOrganisationLanguage(languageCode, resetSelect);
            }}
          />
          <InlineSelectEditor
            className="organisation-settings__section-field"
            label={localeLookup('translations.Time format')}
            selectId="timeFormat"
            defaultValue={currentTimeFormat()}
            options={timeFormatOptions()}
            onSubmit={(timeFormat) => {
              onChangePermission({
                name: 'TimeFormatSetting',
                enabled: true,
                value: timeFormat,
              });
            }}
          />
          <InlineSelectEditor
            className="organisation-settings__section-field"
            label={localeLookup('translations.Date format')}
            selectId="dateFormat"
            defaultValue={currentDateFormat()}
            options={dateFormatOptions()}
            onSubmit={(dateFormat) => {
              onChangePermission({
                name: 'DateFormatSetting',
                enabled: true,
                value: dateFormat,
              });
            }}
          />
          <Field
            className="organisation-settings__section-field"
            label={localeLookup('translations.Time zone')}
          >
            <AutocompleteSelect
              defaultValue={timeZone}
              name="timezone"
              onChange={(selectedOption) => {
                onChangeTimeZone(selectedOption.value);
              }}
              options={timeZonesNames.map((name) => ({
                label: name.replaceAll('_', ' '),
                value: name,
              }))}
            ></AutocompleteSelect>
          </Field>
        </div>
      </OrganisationSettingsSection>
    );
  };

  renderPermissionGroupSections = () => {
    const { permissions, permissionGroups, onChangePermission } = this.props;
    return (
      permissionGroups &&
      permissionGroups.map((group, index) => {
        return (
          <OrganisationSettingsSection key={index}>
            <OrganisationSettingsSectionHeader title={group.name} />
            <ul className="organisation-settings__permissions-list">
              {permissions &&
                permissions.map((permission, index) => {
                  if (permission.group !== group.id) return null;
                  return (
                    <li
                      className="organisation-settings__permission"
                      key={index}
                    >
                      <label
                        className="organisation-settings__permission-label"
                        htmlFor={permission.name}
                      >
                        {permission.displayName}
                        {permission.subTitle && (
                          <p className="organisation-settings__permission-subtitle">
                            {permission.subTitle}
                          </p>
                        )}
                      </label>
                      <SwitchCheckbox
                        className="organisation-settings__permission-switch"
                        onChange={() => {
                          onChangePermission({
                            name: permission.name,
                            enabled: !permission.enabled,
                          });
                        }}
                        id={permission.name}
                        isChecked={permission.enabled}
                        disabled={permission.disabled}
                      />
                    </li>
                  );
                })}
            </ul>
          </OrganisationSettingsSection>
        );
      })
    );
  };

  renderSupportSection = () => {
    const accessLevels = [
      {
        title: localeLookup('translations.System administrator'),
        id: ACCESS_LEVELS.systemAdministrator,
      },
      {
        title: localeLookup('translations.Administrator'),
        id: ACCESS_LEVELS.administrator,
      },
      {
        title: localeLookup('translations.Content administrator'),
        id: ACCESS_LEVELS.contentAdministrator,
      },
      {
        title: localeLookup('translations.User administrator'),
        id: ACCESS_LEVELS.userAdministrator,
      },
      {
        title: localeLookup('translations.Person administrator'),
        id: ACCESS_LEVELS.personAdministrator,
      },
      {
        title: localeLookup('translations.Organisation administrator'),
        id: ACCESS_LEVELS.teamadministrator,
      },
      {
        title: localeLookup('translations.Module owner'),
        id: ACCESS_LEVELS.moduleOwner,
      },
      {
        title: localeLookup('translations.Role owner'),
        id: ACCESS_LEVELS.roleOwner,
      },
      {
        title: localeLookup('translations.Module editor'),
        id: ACCESS_LEVELS.moduleEditor,
      },
      {
        title: localeLookup('translations.Role editor'),
        id: ACCESS_LEVELS.roleEditor,
      },
    ];
    const activeSetting = this.getSupportSetting();
    return (
      <OrganisationSettingsSection>
        <OrganisationSettingsSectionHeader
          title={localeLookup('translations.Support')}
        />
        <ul className="organisation-settings__permissions-list">
          <li className="organisation-settings__permission">
            <label className="organisation-settings__permission-label">
              {localeLookup('translations.All')}
            </label>
            <RadioButton
              id="all"
              onChange={this.onChangeSupportSetting}
              value="all"
              isChecked={activeSetting === 'all'}
              name="support"
            />
          </li>
          <li className="organisation-settings__permission">
            <label className="organisation-settings__permission-label">
              {localeLookup('translations.None')}
            </label>
            <RadioButton
              id="none"
              onChange={this.onChangeSupportSetting}
              value="none"
              isChecked={activeSetting === 'none'}
              name="support"
            />
          </li>
          <li className="organisation-settings__permission organisation-settings__permission--has-children">
            <div className="organisation-settings__permission-inner">
              <label className="organisation-settings__permission-label">
                {localeLookup('translations.Limited')}
              </label>
              <RadioButton
                id="limited"
                onChange={this.onChangeSupportSetting}
                value="limited"
                isChecked={activeSetting === 'limited'}
                name="support"
              />
            </div>
            {activeSetting === 'limited' && (
              <ul className="organisation-settings__checkbox-list">
                {accessLevels.map((level) => {
                  return (
                    <li
                      className="organisation-settings__checkbox-list-item"
                      key={level.id}
                    >
                      <Checkbox
                        id={level.id}
                        onChange={this.onChangeSupportAccessLevels}
                        key={level.id}
                        isChecked={this.isSupportAccessLevelEnabled(level.id)}
                        className="organisation-settings__checkbox"
                        style="stroked"
                        label={level.title}
                        labelPosition="right"
                      ></Checkbox>
                    </li>
                  );
                })}
              </ul>
            )}
          </li>
        </ul>
      </OrganisationSettingsSection>
    );
  };

  render() {
    return (
      <div className="organisation-settings">
        {this.renderMainSettingsSection()}
        {this.renderSupportSection()}
        {this.renderPermissionGroupSections()}
        {this.renderApiSection()}
        {this.renderUserProvisioningSection()}
        {this.renderADSection()}
      </div>
    );
  }
}

export default WithModals(OrganisationSettings);
